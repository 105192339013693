import React, { Fragment, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import _, { isEmpty } from 'lodash';
import styled from 'styled-components';

import SvgArrowdDownIcon from 'assets/icons/ArrowdDown';
import SvgLogoutMenuIcon from 'assets/icons/LogoutMenu';
import SvgNotificationIcon from 'assets/icons/Notification';
import SvgSettingsIcon from 'assets/icons/Settings';

import {
  getEmptyConnectionSettings,
  ConnectionSettingsType,
  validator as ConnectionSettingsValidator,
} from 'pages/VismaSettings/model/connectionSettings';
import ConnectionSettings from 'pages/VismaSettings/components/Drawers/ConnectionSettings';
import MeritPalkSettings from 'pages/MeritPalk/components/Drawers/MeritPalkSettings';
import MeritPalkSettingValidator, { getEmptyMeritPalkSetting } from 'pages/MeritPalk/model/settings';
import CompanyProfileDrawer from 'pages/Admin/components/Drawers/CompanyProfileDrawer';
import CompanyInfoValidator, { getEmptyCompanyInfo } from 'pages/Admin/model/company';

import Box from 'shared/components/Box';
import Button from 'shared/components/Button';
import DropDownAction, { MenuOption } from 'shared/components/Layout/NavBar/DropDownAction';
import NotificationDrawer from 'shared/components/Notification/NotificationDrawer';
import Avatar from 'shared/components/DataTable/Avatar';
import ConfirmDialog from 'shared/components/ConfirmDialog';

import { useBreakpoint } from 'shared/hooks/useBreakpoint';
import useMutation from 'shared/hooks/useMutation';
import useRouter from 'shared/hooks/useRouter';

import { capitalizeFirstChar, getTranslatedWord, serverUploadPath } from 'shared/utils/tools';

import { drawerProcessing, hideDialog, hideDrawer, showDialog, showDrawer } from 'shared/redux/app/reducer';
import {
  setGlobalFilters,
  setPeopleWorkingHoursColumns,
  _setWorkingHoursFilter,
  _setNotifications,
  setIsSwitching,
  setPersona,
} from 'shared/redux/data/reducer';
import { dataSelector } from 'shared/redux/selectors';
import { logoutRequest } from 'shared/redux/auth/reducer';

import { Company, MeritPalkSetting, UserNotification } from 'shared/types/api';
import { ADMIN, MANAGER, WORKER, persona as personaTypes } from 'shared/types';
import { permissionSelector, authSelector } from 'shared/hocs/withAuth';
import useIntercomMethods from 'shared/hooks/useIntercomMethods';

import NotificationAction from './NotificationAction';
import DropDownDrawer, { DropdownOptions } from '../Drawer/DropDownDrawer';

function NavBarAction() {
  const history = useHistory();
  const { shutdownIntercom, handleOnChangePage } = useIntercomMethods();
  const { auth: user, isAdmin, isMainCompanyUser } = useSelector(authSelector);
  const {
    workSpace,
    persona,
    notifications: { list },
  } = useSelector(dataSelector);
  const [hasUnread, setHasUnread] = useState<boolean>(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const router = useRouter();
  const pathname = String(router.pathname).split('/')?.[1];
  const key = pathname == 'admin' ? String(router.pathname).split('/')?.[2] : pathname;

  const {
    CAN_EDIT_PREFERENCES,
    CAN_MANAGE_BOXES,
    CAN_MANAGE_WORKTYPES,
    HAS_MANAGER_RIGHTS,
    CAN_EDIT_OWN_PROFILE,
    CAN_MANAGE_ADMIN_PAGES,
    CAN_MANAGE_SALARY,
    IS_VISMA_ACTIVE,
    IS_MERIT_PALK_ACTIVE,
    BOXES_ACTIVE,
    IS_INNER_RANGE_ACTIVE,
    IS_GEOFENCE_ACTIVE,
  } = useSelector(permissionSelector);

  const [, onUpdateSettings] = useMutation({
    url: `/admin/netvisor/settings`,
    method: 'POST',
  });

  const [, onUpdateCompany] = useMutation({
    url: `/companies/${workSpace?.selected?.id}/update`,
    method: 'POST',
  });

  const [, onUpdateMeritPalkSetting] = useMutation({
    url: `/merit-palk/companies/${workSpace?.selected?.id}/settings`,
    method: 'POST',
    showMessage: true,
  });

  const [, updateUserCompany] = useMutation({
    url: `/users/${user?.id}/company/update`,
    method: 'POST',
    showMessage: false,
  });

  const notifications = useMemo(() => {
    const filtered = list?.filter((item: any) => {
      return item?.type !== 'App\\Notifications\\WorkUpdate';
    }) as UserNotification[];

    const unread = filtered?.filter((item: any) => {
      return item?.read_at === null;
    });

    setHasUnread(unread?.length > 0);
    return filtered;
  }, [list]);

  const settingOptions = useMemo(() => {
    const options = [];

    if (!isAdmin && persona === WORKER) {
      options.push({
        id: 'profile',
        label: getTranslatedWord('Profile', 'profile'),
        onClick: () => handleOnChangePage('/profile'),
      });
    }

    if (HAS_MANAGER_RIGHTS && persona === MANAGER && isMainCompanyUser) {
      options.push({
        id: 'company-profile',
        label: capitalizeFirstChar(getTranslatedWord('Company Profile', 'profile')),
        onClick: handleSelectCompanyProfile,
      });
    }

    if (persona === MANAGER || persona === WORKER) {
      options.push({
        id: 'preference',
        label: getTranslatedWord('Preferences', 'preferences'),
        onClick: () => handleOnChangePage('/preference'),
      });
    }

    if (persona === WORKER) {
      options.push({
        id: 'requests',
        label: getTranslatedWord('Requests'),
        onClick: () => handleOnChangePage('/requests'),
      });
    }

    if (persona === MANAGER && CAN_MANAGE_WORKTYPES) {
      options.push({
        id: 'worktype',
        label: getTranslatedWord('Work types', 'work_types'),
        onClick: () => handleOnChangePage('/worktype'),
      });
    }

    if (persona === MANAGER && IS_INNER_RANGE_ACTIVE) {
      options.push({
        id: 'inner-range',
        label: getTranslatedWord('Inner range'),
        onClick: () => handleOnChangePage('/inner-range'),
      });
    }

    if (persona === MANAGER && IS_GEOFENCE_ACTIVE) {
      options.push({
        id: 'gateway',
        label: getTranslatedWord('Gateway'),
        onClick: () => handleOnChangePage('/gateway'),
      });
    }

    if (persona === MANAGER && CAN_MANAGE_SALARY && IS_VISMA_ACTIVE) {
      options.push({
        id: 'visma',
        label: capitalizeFirstChar(getTranslatedWord('Visma settings')),
        onClick: () => handleOnChangePage('/visma'),
      });
    }

    if (persona === MANAGER && CAN_MANAGE_SALARY && IS_MERIT_PALK_ACTIVE) {
      options.push({
        id: 'merit-palk',
        label: capitalizeFirstChar(getTranslatedWord('Merit Palk')),
        onClick: handleMeritPalkSetting,
      });
    }

    if (persona === MANAGER && CAN_MANAGE_BOXES && BOXES_ACTIVE) {
      options.push({
        id: 'box',
        label: getTranslatedWord('Boxes', 'boxes'),
        onClick: () => handleOnChangePage('/box'),
      });
    }

    if (persona === MANAGER && HAS_MANAGER_RIGHTS) {
      options.push({
        id: 'notifications',
        label: getTranslatedWord('Notifications'),
        onClick: () => handleOnChangePage('/notifications'),
      });
    }

    if (persona === ADMIN && CAN_MANAGE_ADMIN_PAGES) {
      options.push(
        {
          id: 'clients',
          label: capitalizeFirstChar(getTranslatedWord('Clients')),
          onClick: () => history.push('/admin/clients'),
        },
        {
          id: 'audits',
          label: capitalizeFirstChar(getTranslatedWord('Audit logs')),
          onClick: () => history.push('/admin/audits'),
        },
        {
          id: 'notifications',
          label: capitalizeFirstChar(getTranslatedWord('Notifications')),
          onClick: () => history.push('/admin/notifications'),
        },
        {
          id: 'visma-admin',
          label: capitalizeFirstChar(getTranslatedWord('Visma settings')),
          onClick: handleSelectAdminVismaSettings,
        },
      );
    }

    return options as DropdownOptions[];
  }, [
    CAN_EDIT_PREFERENCES,
    CAN_MANAGE_BOXES,
    CAN_MANAGE_WORKTYPES,
    HAS_MANAGER_RIGHTS,
    CAN_EDIT_OWN_PROFILE,
    CAN_MANAGE_ADMIN_PAGES,
    CAN_MANAGE_SALARY,
    handleUserProfile,
    handleSelectCompanyProfile,
    handleMeritPalkSetting,
    handleSelectAdminVismaSettings,
    isMainCompanyUser,
    isAdmin,
    persona,
    WORKER,
    MANAGER,
    user,
  ]);

  const avatarOptions = useMemo(() => {
    const options = [];

    if (!isAdmin) {
      options.push({
        id: WORKER,
        label: user?.full_name || '',
        onClick: () => {
          onSetPersona(2);
        },
      });

      options.push({
        id: MANAGER,
        label: user?.default_company?.name || workSpace?.selected?.name || '',
        onClick: () => {
          onSetPersona(1);
        },
      });
    }

    if (isAdmin && persona === MANAGER)
      options.push({
        id: MANAGER,
        label: user?.default_company?.name || workSpace?.selected?.name || '',
        onClick: () => {
          onSetPersona(1);
        },
      });

    if (isAdmin) {
      options.push({
        id: ADMIN,
        label: user?.full_name || '',
        onClick: () => {
          dispatch(setPeopleWorkingHoursColumns([]));
          dispatch(_setWorkingHoursFilter({}));
          dispatch(setGlobalFilters({}));
          onSetPersona(0);
        },
      });
    }

    return options;
  }, [isAdmin]);

  const notificationActions = [
    {
      label: getTranslatedWord('Mark all as read'),
      onClick: handleReadAll,
      conditionalRendering: (row: any) => true,
    },
    {
      label: getTranslatedWord('View full feed'),
      onClick: handleShowNotificationDrawer,
      conditionalRendering: (row: any) => true,
    },
    {
      label: getTranslatedWord('Clear all'),
      onClick: handleDeleteAllNotification,
      conditionalRendering: (row: any) => true,
      color: 'red',
    },
  ];

  const [, onReadAll] = useMutation({
    method: 'POST',
    showMessage: false,
  });

  const [, onDeleteAll] = useMutation({
    method: 'POST',
  });

  const settingMenuAction = {
    label: getTranslatedWord('Logout', 'logout'),
    onClick: handleLogout,
    icon: <SvgLogoutMenuIcon />,
  };

  const breakpoint = useBreakpoint();
  const dispatch = useDispatch();

  const isDesktop = useMemo(() => {
    return breakpoint === 'desktop';
  }, [breakpoint]);

  const userWorkspace = useMemo(() => {
    return workSpace?.selected || user?.default_company;
  }, [workSpace, user]);

  const getImageURL = () => {
    if ([WORKER, ADMIN].includes(persona)) {
      if ((user?.thumb_nail_path || user?.photo) && user?.photo_cache) {
        return `${serverUploadPath(user?.thumb_nail_path || user?.photo)}?cache_id=${user?.photo_cache}`;
      }
    } else {
      if (!isEmpty(userWorkspace)) {
        return `${serverUploadPath(userWorkspace?.thumb_nail_path || userWorkspace?.logo)}?cache_id=${
          userWorkspace?.logo_cache
        }`;
      }
    }

    return '';
  };

  const menu = useMemo(
    () => (
      <StyleProfileMenu variant="anchor">
        {isDesktop && (
          <>
            <ProfileImageContainer>
              <StyledAvatar
                name={
                  [WORKER, ADMIN].includes(persona)
                    ? user?.full_name
                    : workSpace?.selected?.name || user?.default_company?.name
                }
                imageUrl={getImageURL()}
              />
            </ProfileImageContainer>
            <ProfileName>
              {[WORKER, ADMIN].includes(persona)
                ? user?.full_name
                : workSpace?.selected?.name || user?.default_company?.name}
            </ProfileName>
            {user?.is_manager && <SvgArrowdDownIcon />}
          </>
        )}
        {!isDesktop && (user?.is_manager || user?.is_admin) && (
          <DrawerContainer onClick={() => handleShowDropDownDrawer(true, user?.is_manager || user?.is_admin)}>
            <MobileAvatarContainer>
              <ProfileImageContainer>
                <StyledAvatar
                  name={
                    [WORKER, ADMIN].includes(persona)
                      ? user?.full_name
                      : workSpace?.selected?.name || user?.default_company?.name
                  }
                  imageUrl={getImageURL()}
                />
              </ProfileImageContainer>
              <SvgArrowdDownIcon />
            </MobileAvatarContainer>
          </DrawerContainer>
        )}
      </StyleProfileMenu>
    ),
    [workSpace, isDesktop, user],
  );

  return (
    <MenuActionContainer>
      {isDesktop && (
        <NotificationAction
          right="12px !important"
          top="unset"
          items={notifications}
          actions={notificationActions}
          isOpenDrawer={isOpenDrawer}
        >
          <SvgNotificationIcon hasUnread={hasUnread} />
        </NotificationAction>
      )}
      {!isDesktop && (
        <DrawerContainer onClick={handleShowNotificationDrawer}>
          <SvgNotificationIcon hasUnread={hasUnread} />
        </DrawerContainer>
      )}
      {isDesktop && (
        <Fragment>
          <DropDownAction
            isNavMenu
            closeAfterClick
            menuAction={user?.is_manager || user?.is_admin ? undefined : settingMenuAction}
            menuOption={settingOptions}
            selected={key}
            right="12px !important"
            top="74px"
          >
            <SvgSettingsIcon />
          </DropDownAction>
          {user?.is_manager && (
            <DropDownAction
              top="74px"
              isNavMenu
              menuAction={settingMenuAction}
              menuOption={avatarOptions}
              selected={persona}
              closeAfterClick
            >
              {menu}
            </DropDownAction>
          )}
          {!user?.is_manager && menu}
        </Fragment>
      )}
      {!isDesktop && (
        <Fragment>
          <MobileDrawerContainer onClick={() => handleShowDropDownDrawer(false, user?.is_manager || user?.is_admin)}>
            <SvgSettingsIcon />
          </MobileDrawerContainer>
          {menu}
        </Fragment>
      )}
    </MenuActionContainer>
  );

  function handleShowDropDownDrawer(isAvatarMenu: boolean, isManager: any) {
    return dispatch(
      showDrawer({
        component: DropDownDrawer,
        props: {
          title: getTranslatedWord('Menu'),
          labelId: 'navbar-menu-drawer',
          options: isAvatarMenu ? avatarOptions : settingOptions,
          action: setLogoutAction(isAvatarMenu, isManager),
          isAvatar: isAvatarMenu,
          selected: key,
          selectedPersona: persona,
          hasContinue: false,
          hasCancel: true,
          cancelLabel: getTranslatedWord('Close'),
        },
      }),
    );
  }

  function setLogoutAction(isAvatarMenu: boolean, isManager: any) {
    if (!isAvatarMenu && !isManager) {
      return settingMenuAction;
    }

    if (isAvatarMenu && isManager) {
      return settingMenuAction;
    }

    return null;
  }

  function onSetPersona(type: number) {
    if (personaTypes[type] === persona) return;

    switch (type) {
      case 0:
        updateUserCompany({
          data: {
            company_id: 0,
          },
          onSuccess() {
            dispatch(setIsSwitching(true));
            dispatch(setPersona('admin'));
            setTimeout(() => {
              window.location.href = '/';
              setTimeout(() => {
                dispatch(setIsSwitching(false));
              }, 500);
            }, 1500);
          },
        });
        break;
      default:
        dispatch(setIsSwitching(true));
        dispatch(setPersona(personaTypes[type]));
        setTimeout(() => {
          window.location.href = '/';
          setTimeout(() => {
            dispatch(setIsSwitching(false));
          }, 500);
        }, 1500);
    }
  }

  function handleLogout() {
    dispatch(logoutRequest());
    shutdownIntercom();

    setTimeout(() => {
      document.querySelector('.intercom-launcher')?.classList.remove('intercom-booted');
      document.querySelector('.intercom-icon-open')?.classList.remove('intercom-open');
      document.querySelector('.intercom-icon-close')?.classList.remove('intercom-open');
    }, 300);
  }

  function handleUserProfile(data?: MenuOption) {
    history.push('/profile');
  }

  function handleSelectCompanyProfile(data?: MenuOption) {
    dispatch(
      showDrawer({
        component: CompanyProfileDrawer,
        props: {
          title: getTranslatedWord('Company Profile'),
          labelId: 'navbar-company-profile-drawer',
          onValid: (data: Company) => {
            onUpdateCompany({ data });
          },
          initialFields: getEmptyCompanyInfo,
          validator: CompanyInfoValidator,
          companyId: workSpace?.selected?.id,
          // hasContinue: false,
          hasCancel: false,
        },
      }),
    );
  }

  function handleMeritPalkSetting(data?: MenuOption) {
    dispatch(
      showDrawer({
        component: MeritPalkSettings,
        props: {
          title: getTranslatedWord('Merit Palk Setting'),
          labelId: 'navbar-merit-palk-setting-drawer',
          onValid: (data: MeritPalkSetting) => {
            onUpdateMeritPalkSetting({
              data,
              onError() {
                dispatch(drawerProcessing(false));
              },
            });
          },
          initialFields: getEmptyMeritPalkSetting,
          validator: MeritPalkSettingValidator,
          companyId: workSpace?.selected?.id,
        },
      }),
    );
  }

  function handleSelectAdminVismaSettings() {
    return dispatch(
      showDrawer({
        component: ConnectionSettings,
        props: {
          title: getTranslatedWord('Connection Settings'),
          labelId: 'navbar-visma-setting-drawer',
          onValid: handleSaveVismaSettings,
          initialFields: getEmptyConnectionSettings,
          validator: ConnectionSettingsValidator,
        },
      }),
    );
  }

  function handleSaveVismaSettings(data: ConnectionSettingsType) {
    onUpdateSettings({
      data,
      message: getTranslatedWord('Settings updated successfully'),
      onSuccess() {
        dispatch(hideDrawer());
      },
    });
  }

  function handleShowNotificationDrawer() {
    setIsOpenDrawer(true);
    return dispatch(
      showDrawer({
        component: NotificationDrawer,
        props: {
          title: getTranslatedWord('Notifications'),
          labelId: 'navbar-notifications-drawer',
          hasContinue: notifications?.length > 0,
          hasCancel: true,
          hasExtra: notifications?.length > 0,
          continueLabel: getTranslatedWord('Read'),
          cancelLabel: getTranslatedWord('Close'),
          extraLabel: getTranslatedWord('Clear'),
          onValid: () => {
            onReadAll({
              url: `notifications/read/all`,
              onSuccess(response: any) {
                setIsOpenDrawer(false);
                setHasUnread(false);
                dispatch(_setNotifications(response?.data));
                dispatch(hideDrawer());
              },
              onError() {
                dispatch(drawerProcessing(false));
              },
            });
          },
          onCancel: () => {
            setIsOpenDrawer(false);
            dispatch(hideDrawer());
          },
          onExtra: handleDeleteAllNotification,
        },
      }),
    );
  }

  function handleReadAll() {
    onReadAll({
      url: `notifications/read/all`,
      onSuccess(response: any) {
        dispatch(_setNotifications(response?.data));
        setHasUnread(false);
      },
    });
  }

  function handleDeleteAllNotification() {
    setIsOpenDrawer(true);

    dispatch(
      showDialog({
        component: ConfirmDialog,
        props: {
          variant: 'danger',
          title: getTranslatedWord('Confirm action', 'confirm_action'),
          message: getTranslatedWord('Are you sure you want to clear all notifications?'),
          onValid: (data: any) => {
            onDeleteAll({
              url: `notifications/delete/all`,
              onSuccess() {
                setIsOpenDrawer(false);
                dispatch(_setNotifications([]));
                dispatch(hideDialog());
              },
            });
          },
          onClose: () => {
            setIsOpenDrawer(false);
            dispatch(hideDialog());
          },
          onCancel: () => {
            setIsOpenDrawer(false);
            dispatch(hideDialog());
          },
          onError() {
            dispatch(drawerProcessing(false));
          },
          continueLabel: getTranslatedWord('Confirm'),
        },
      }),
    );
  }
}

const StyleProfileMenu = styled(Button)`
  > *:not(:last-child) {
    margin-right: 15px;
  }

  ${({ theme }) => theme?.mediaQueries?.largeDown} {
    padding: 0;
    min-width: fit-content;
  }
`;

const DrawerContainer = styled.div`
  display: grid;
  align-items: center;
  cursor: pointer;
`;

const StyledAvatar = styled(Avatar)`
  width: 40px;
  height: 40px;
`;

const MenuActionContainer = styled(Box)`
  // min-width: 324px;
  min-width: 250px;
  height: inherit;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${({ theme }) => theme?.mediaQueries?.largeDown} {
    min-width: 50px;
  }
`;

const ProfileImageContainer = styled(Box)`
  height: 48px;
  width: 48px;
  min-height: 48px;
  min-width: 48px;

  border-radius: 100%;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => `
    background: ${theme.colors.gray.light_gray};
  `}

  img {
    width: 100%;
  }
`;

const ProfileContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 32px;

  & > *:not(:last-child) {
    margin-right: 16px;
  }

  ${({ theme }) => theme?.mediaQueries?.largeDown} {
    margin-left: 16px;
  }
`;

const ProfileName = styled(Box)`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  ${({ theme }) => `
    color: ${theme.colors.gray.dark};
  `}
`;

const MobileAvatarContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    margin-left: 8px;
  }
`;

const MobileDrawerContainer = styled(DrawerContainer)`
  margin-right: 8px;
`;

export default NavBarAction;
