import React, { useCallback } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { registerLocale } from 'react-datepicker';
import { IntercomProvider } from 'react-use-intercom';

import { ThemeProvider } from 'styled-components';
import { scoreboardTheme } from 'theme';
import routes from 'routes';
import et from 'date-fns/locale/et';
import fi from 'date-fns/locale/fi';
import 'react-virtualized/styles.css'; // only needs to be imported once
import 'react-tooltip/dist/react-tooltip.css'; // imported once only

import withClearCache from 'shared/hocs/withClearCache';
import { BreakpointProvider } from 'shared/hooks/useBreakpoint';

import ToastContainer from 'shared/components/Layout/ToastContainer';
import Globals from 'shared/components/Globals';
import SwitchAccountLoader from 'shared/components/SwitchAccountLoader';
import CookiePrompt from 'shared/components/CookiePrompt';
import { ErrorBoundary } from 'shared/components/ErrorBoundary';

import store from 'shared/redux/store';
import renderRoutes from 'shared/utils/renderRoutes';

require('pusher-js');

require('moment/min/locales');
require('moment-timezone');
registerLocale('et', et);
registerLocale('fi', fi);

const MainApplication = () => {
  const launcher = document.querySelector('.intercom-launcher');

  const onHide = useCallback(() => launcher?.classList.add('intercom-open'), []);
  const onShow = useCallback(() => launcher?.classList.remove('intercom-open'), []);

  return (
    <ErrorBoundary>
      <BreakpointProvider>
        <ThemeProvider theme={scoreboardTheme}>
          <ReduxProvider store={store}>
            <IntercomProvider
              appId={process.env.REACT_APP_INTERCOM_APP_ID || 'sz6fpvbb'}
              onShow={onHide}
              onHide={onShow}
            >
              <CookiePrompt
                message="We use cookies to ensure you get the best experience on our site."
                url="https://www.hours.ee/en/cookie-policy/"
              />
              <SwitchAccountLoader />
              <ToastContainer />
              <Globals />
              <BrowserRouter>{renderRoutes(routes, {}, {}, true)}</BrowserRouter>
            </IntercomProvider>
          </ReduxProvider>
        </ThemeProvider>
      </BreakpointProvider>
    </ErrorBoundary>
  );
};

const ClearCacheComponent = withClearCache(MainApplication);

const App = () => {
  return <ClearCacheComponent />;
};

export default App;
