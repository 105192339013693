import * as React from 'react';

import Box from 'shared/components/Box';
import HoursLogoIcon from 'assets/icons/hourslogo.svg';
import styled from 'styled-components';

const Fallback = () => (
  <Container>
    <Wrapper>
      <img src={HoursLogoIcon} />
      <div className="spinner spinner-settings" />
    </Wrapper>
  </Container>
);

const Container = styled(Box)`
  display: flex;
  height: 100vh;
  justify-content: center;
  margin: auto;
`;

const Wrapper = styled(Box)`
  display: block;
  align-items: center;
  padding-top: 24px;
`;
export default Fallback;
