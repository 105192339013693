import React, { useEffect, Fragment, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IdleTimer from 'react-idle-timer';

import { createSelector } from '@reduxjs/toolkit';

import { isEmpty } from 'lodash';

import {
  fetchMissingWorks,
  fetchDivisions,
  fetchWorkTypes,
  fetchPeople,
  fetchAllPeople,
  fetchLoginBoxes,
  fetchOvertimeGroups,
  fetchWorkshiftGroups,
  fetchAllowedPauseGroups,
  fetchDailyAllowanceGroups,
  fetchCompensationGroups,
  fetchVacationTypes,
  fetchInnerRangeDoors,
  fetchInnerRangeSettings,
  fetchGeofenceGateways,
  fetchNetvisorDimensions,
  fetchNetvisorSettings,
  fetchNotifications,
  fetchTranslations,
  fetchCompanySettings,
  setSelectedCompany,
  setPersona,
  _changeLocale,
  fetchHolidays,
} from 'shared/redux/data/reducer';

import { logoutRequest } from 'shared/redux/auth/reducer';
import { dialogProcessing, hideAllDialogs, hideAllDrawers, showDialog, showError } from 'shared/redux/app/reducer';
import { authSelector, dataSelector } from 'shared/redux/selectors';
import { GlobalState } from 'shared/redux/reducer';

import { MANAGER, WORKER, ADMIN } from 'shared/types';
import Toasts from 'shared/components/Toasts';

import Echo from 'laravel-echo';
import CompleteRegistrationForm from './Forms/CompleteRegistrationForm';
import { completeRegistrationValidator, getEmptyRegistration } from './Forms/models/completeRegistration';
import ConfirmDialog from './ConfirmDialog';

import useLocaleChange from 'shared/hooks/useLocaleChange';
import useIntercomMethods from 'shared/hooks/useIntercomMethods';
import useMutation from 'shared/hooks/useMutation';

import { getTourIdByLanguage } from 'shared/utils/formats';
import { getTranslatedWord } from 'shared/utils/tools';

function Globals() {
  let idleTimerRef = useRef(null);
  const { bootIntercom, shutdownIntercom } = useIntercomMethods();
  const dispatch = useDispatch();
  const { getLocaleFromStorage } = useLocaleChange();

  const { translation, persona } = useSelector(dataSelector);
  const { auth: user } = useSelector(authSelector);
  const isDrawerProcessing = useSelector((state: GlobalState) => state.app.drawerProcessing);
  const isDialogProcessing = useSelector((state: GlobalState) => state.app.drawerProcessing);

  const [, onCompleteRegister] = useMutation({ method: 'POST' });
  const [, onUpdate] = useMutation({ method: 'POST' });

  const handleResend = (user: any): any => {
    onUpdate({
      message: getTranslatedWord('Resent verification link'),
      url: `users/verification/resend`,
      method: 'POST',
      data: { email: user?.email },
      showMessage: false,
      onSuccess() {
        dispatch(dialogProcessing(false));
      },
    });
  };

  const { isManager, isAdmin } = useSelector(
    createSelector(
      (state: GlobalState) => state.auth.user,
      auth => {
        const isManager = Boolean(auth?.roles?.find(role => role.name === 'manager'));
        const isAdmin = Boolean(auth?.roles?.find(role => role.name === 'admin'));

        return { isManager, isAdmin };
      },
    ),
  );

  const selectedCompany = useSelector((state: GlobalState) => state.data?.workSpace.selected);
  const toast = useSelector((state: GlobalState) => state.app.toast);

  //=============================
  // Forced logout prompt
  //=============================
  const forcedLogout = (message: string) => {
    dispatch(
      showError({
        message: 'Oops!',
        subText: getTranslatedWord(message),
        onDismiss: () => {
          dispatch(logoutRequest());
          shutdownIntercom();
        },
      }),
    );
  };

  //=============================
  // Websocket (Pusher)
  //=============================
  useEffect(() => {
    const options = {
      broadcaster: 'pusher',
      key: process.env.REACT_APP_WEBSOCKET_KEY,
      wsHost: process.env.REACT_APP_WEBSOCKET_WS_HOST,
      wsPort: process.env.REACT_APP_WEBSOCKET_WS_PORT,
      wssPort: process.env.REACT_APP_WEBSOCKET_WSS_PORT,
      disableStats: true,
      enabledTransports: ['ws', 'wss'],
      forceTLS: process.env.REACT_APP_WEBSOCKET_TLS === 'true' ? true : false,
      authEndpoint: `${process.env.REACT_APP_WEBSOCKET_URL}/broadcasting/auth`,
      auth: {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          Accept: 'application/json',
        },
      },
      // cluster: 'mt1',
    };

    const echo = new Echo(options);

    if (user?.id) {
      const channel = echo.private(`users_${user?.id}`);
      channel.notification((data: any) => {
        if (selectedCompany && data?.type === 'App\\Notifications\\WorkerMissingWork') {
          dispatch(fetchMissingWorks(selectedCompany));
        }

        if (user && data?.type === 'App\\Notifications\\UserRoleChanged') {
          setTimeout(() => {
            echo.leave(`users_${user?.id}`);
          }, 100);
          forcedLogout('You are forced to logout due to your account has been updated');
        }

        if (user && data?.type === 'App\\Notifications\\CompanyDisabled') {
          setTimeout(() => {
            echo.leave(`users_${user?.id}`);
          }, 100);
          dispatch(logoutRequest());
          forcedLogout('You are forced to logout due to your account has been disabled');
        }

        dispatch(fetchNotifications());
      });
    }
  }, [JSON.stringify(user), selectedCompany]);

  // //=============================
  // // Updating Persona (rights)
  // //=============================
  // const handleEscape = useCallback((event: any) => {
  //   event.preventDefault();

  //   if (event.key === 'Escape') {
  //     event.stopPropagation();
  //     if (!isDrawerProcessing) {
  //       dispatch(hideAllDrawers());
  //     }

  //     if (!isDialogProcessing) {
  //       dispatch(hideAllDialogs());
  //     }
  //   }
  // }, []);

  // useEffect(() => {
  //   document.addEventListener('keydown', handleEscape, false);
  //   return () => {
  //     document.removeEventListener('keydown', handleEscape, false);
  //   };
  // }, [handleEscape]);

  //=============================
  // Updating Persona (rights)
  //=============================
  useEffect(() => {
    let person = localStorage.getItem('persona') || '';
    if (!isEmpty(person)) {
      person = person.replace(/[^a-zA-Z0-9 ]/g, '');
    }

    if (user && user?.default_company) {
      if (['worker', 'manager'].includes(person)) {
        if (person === 'manager' && !user.is_manager) {
          forcedLogout('You are forced to logout due to your account has been updated');
        }
      }
      dispatch(fetchTranslations());
    }
  }, [user]);

  //=============================
  // Check if account is active
  //=============================
  useEffect(() => {
    if (selectedCompany) {
      const isDisabled = selectedCompany.subscription?.is_login_disabled || false;
      if (isDisabled) {
        dispatch(logoutRequest());
        forcedLogout('You are forced to logout due to your account has been disabled');
      }
    }
  }, [selectedCompany]);

  //=============================
  // Fetch initial data (reducer)
  //=============================
  useEffect(() => {
    if (selectedCompany?.id && user) {
      dispatch(
        fetchDivisions({
          data: selectedCompany,
          rights:
            persona === MANAGER
              ? user?.user_rights?.manager_rights_workspace_ids || []
              : user?.user_rights?.general_rights_workspace_ids || [],
        }),
      );

      dispatch(
        fetchWorkTypes({
          division_ids:
            persona === MANAGER
              ? user?.user_rights?.manager_rights_workspace_ids || []
              : user?.user_rights?.general_rights_workspace_ids || [],
          company_id: selectedCompany?.id,
        }),
      );

      if (user?.schedule_options && user?.schedule_options?.calendar_code !== '') {
        dispatch(fetchHolidays({ code: user?.schedule_options?.calendar_code }));
      }

      dispatch(fetchMissingWorks(selectedCompany));
      dispatch(fetchPeople(selectedCompany));
      dispatch(fetchAllPeople(selectedCompany));
      dispatch(fetchLoginBoxes(selectedCompany));
      dispatch(fetchOvertimeGroups(selectedCompany));
      dispatch(fetchWorkshiftGroups(selectedCompany));
      dispatch(fetchAllowedPauseGroups(selectedCompany));
      dispatch(fetchDailyAllowanceGroups(selectedCompany));
      dispatch(fetchCompensationGroups(selectedCompany));
      dispatch(fetchVacationTypes([selectedCompany]));
      dispatch(fetchInnerRangeDoors(selectedCompany));
      dispatch(fetchInnerRangeSettings(selectedCompany));
      dispatch(fetchGeofenceGateways(selectedCompany));
      dispatch(fetchNetvisorDimensions(selectedCompany));
      dispatch(fetchNetvisorSettings(selectedCompany));
      dispatch(fetchNotifications());
      dispatch(fetchCompanySettings(selectedCompany));
    }
  }, [user, selectedCompany]);

  //=============================
  // Change initial locale
  //=============================
  useEffect(() => {
    if (user && user?.language) {
      const locale = user.language || localStorage.getItem('locale');
      if (translation.current) {
        dispatch(_changeLocale(locale));
      }
    }
  }, [user?.language, translation.data]);

  //=============================
  // Complete registration
  //=============================
  useEffect(() => {
    if (!user) {
      return;
    }

    // if a non-admin user has no company yet
    if (!isAdmin && !user?.default_company) {
      setTimeout(() => {
        dispatch(
          showDialog({
            component: CompleteRegistrationForm,
            props: {
              variant: 'primary',
              title: getTranslatedWord('Complete registration'),
              is_spoof_email: user?.is_email_spoof,
              validator: completeRegistrationValidator,
              initialFields: {
                ...getEmptyRegistration,
                email: user?.email || '',
              },
              onValid: async (data: any) => {
                const formData = new FormData();

                const keys = Object.keys(data);

                keys.forEach(key => {
                  if (data[key]) {
                    formData.append(key, data[key]);
                  }
                });

                await onCompleteRegister({
                  url: `/v2/users/${user?.id}/register/complete`,
                  data: formData,
                });

                dispatch(dialogProcessing(false));
                dispatch(setPersona(MANAGER));

                setTimeout(() => {
                  const language = getLocaleFromStorage() || user?.language;
                  const tourId = getTourIdByLanguage(language);
                  window.location.href = '/dashboard?product_tour_id=' + tourId;
                }, 1500);
              },
              hasClose: false,
              hasCancel: false,
              continueLabel: getTranslatedWord('Confirm'),
            },
          }),
        );
      }, 1000);
    }

    // try to set company for completely registered user
    if (user?.default_company && !localStorage.getItem('selected_company')) {
      dispatch(setSelectedCompany(user?.default_company));
    }

    if (!localStorage.getItem('persona')) {
      if (isAdmin) dispatch(setPersona(ADMIN));
      else if (isManager) dispatch(setPersona(MANAGER));
      else dispatch(setPersona(WORKER));
    }
  }, [user]);

  //=============================
  // Email Verification Modal
  //=============================
  useEffect(() => {
    if (!user?.default_company || user?.email_verified_at) {
      return;
    }

    //   // If email is a spoof email
    //   if (user?.is_email_spoof) {
    //     dispatch(
    //       showDialog({
    //         component: ChangeEmailForm,
    //         props: {
    //           variant: 'primary',
    //           title: getTranslatedWord('Email verification'),
    //           validator: emailValidator,
    //           initialFields: {
    //             ...getEmptyEmail,
    //             email: user?.email || '',
    //           },
    //           onValid: (data: any) => {
    //             onUpdate({
    //               url: `v2/users/${user.id}/update/email`,
    //               data: data,
    //             });
    //           },
    //           continueLabel: getTranslatedWord('Confirm'),
    //         },
    //       }),
    //     );
    //     return;
    //   }

    if (user?.default_company?.subscription?.is_trial_over) {
      dispatch(
        showDialog({
          component: ConfirmDialog,
          props: {
            variant: 'primary',
            title: getTranslatedWord('Email verification'),
            message: getTranslatedWord(
              'Your email is unverified and your trial has ended. Verify your email if you want to continue using the Hours software.',
            ),
            onValid: () => {
              dispatch(hideAllDialogs());
              window.location.reload();
            },
            onCancel: () => {
              dispatch(dialogProcessing(true));
              handleResend(user);
            },
            hasCancel: true,
            hasClose: false,
            continueLabel: getTranslatedWord('Refresh'),
            cancelLabel: getTranslatedWord('Resend verification'),
          },
        }),
      );
      return;
    }
  }, [user]);

  //=============================
  // Intercom chat (messaging)
  //=============================
  useEffect(() => {
    if (user && user?.default_company && !isAdmin) {
      bootIntercom(user);
    }
  }, [user]);

  return (
    <Fragment>
      {user && (
        <IdleTimer
          ref={(ref: any) => {
            idleTimerRef = ref;
          }}
          timeout={1.8e7}
          onIdle={() => {
            setTimeout(() => {
              dispatch(hideAllDialogs());
              dispatch(hideAllDrawers());
              dispatch(logoutRequest());
              shutdownIntercom();
            }, 3000);
          }}
          debounce={250}
        />
      )}

      <Toasts toast={toast} delay={toast?.delay} withClose={toast?.withClose} alwaysVisible={toast?.alwaysVisible} />
    </Fragment>
  );
}

export default Globals;
