import moment from 'moment';

export const dateFormat = 'DD.MM.YYYY';
export const dateFormat2 = 'YYYY-MM-DD';
export const dateFormat3 = 'MM/DD/YYYY';
export const timeFormat = 'HH:mm';
export const nonUTCFormat = 'YYYY-MM-DDTHH:mm:ss';

export const PER_PAGE = 40;
export const SIZE = 40;

export const tabs = [
  {
    key: 'all',
    label: 'All',
  },
  {
    key: 'active',
    label: 'Active',
  },
  {
    key: 'inactive',
    label: 'Inactive',
  },
];

export const formatTimeDigits = (figures: number) =>
  figures.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });

export function formatSecondsToHHmm(seconds: number = 0, isDecimal?: boolean): string {
  var h = Math.floor(seconds / 3600);
  var m = Math.floor((seconds % 3600) / 60);
  var s = Math.floor((seconds % 3600) % 60);

  if (isDecimal) {
    if (h < 0) {
      return getSeparator((m + s / 60).toFixed(2));
    }
    return getSeparator((h + m / 60).toFixed(2));
  } else {
    if (h < 0) {
      return `${m}:${s}`;
    }

    if (h < 10 && m < 10) {
      return `0${h}:0${m}`;
    } else if (h < 10 && m > 10) {
      return `0${h}:${m}`;
    } else if (h > 10 && m < 10) {
      return `${h}:0${m}`;
    } else {
      return `${h}:${m}`;
    }
  }
}

export function getPrefix(value: any) {
  if (value == 1 || value == 2) {
    return '18';
  }

  if (value == 3 || value == 4) {
    return '19';
  }

  return '20';
}

export function getSeparator(value: string, locale: string | string[] = 'et') {
  let userLocale = locale;
  if (localStorage.getItem('locale')) {
    userLocale = String(localStorage.getItem('locale'));
  } else if (navigator.languages != undefined) {
    userLocale = navigator.languages[0];
  }
  return Number(value).toLocaleString(userLocale, {
    minimumFractionDigits: 2,
  });
}

export function formatDuration(seconds?: number, isDecimal?: boolean): string {
  if (seconds) {
    if (isDecimal) {
      const hours = seconds / 3600;
      return hours.toFixed(2);
    }

    var h = Math.floor(seconds / 3600);
    var m = Math.floor((seconds % 3600) / 60);
    return `${String(h).padStart(2, '0')}:${String(m).padStart(2, '0')}`;
  }

  return isDecimal ? getSeparator('0.0') : '00:00';
}

export function formatDurationToDecimal(seconds?: number): number {
  try {
    return seconds ? seconds / 3600 : 0;
  } catch (error) {
    return 0;
  }
}

export function getFormattedTimeDuration(secs: number) {
  const minutes = moment.duration(secs, 'seconds').asMinutes();
  const hours = moment.duration(minutes, 'minutes').asHours();

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
}

export const formatTimeByTimezone = (date?: any, timezone = 'Europe/Helsinki') => {
  // user browser timezone
  const now = moment();
  const localOffset = now.utcOffset();

  // change to work log timezone
  now.tz(timezone);
  const centralOffset = now.utcOffset();

  const diff = centralOffset - localOffset;

  return moment(date).add(diff, 'minutes');
};

export const genericTexts = {
  isRequired: 'This field is required',
  isNumber: 'Must be a number',
};

export const slugify = (phrase: string) => {
  return phrase
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');
};

export const recurring = () => {
  const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  const frequency = [
    {
      label: 'Daily',
      value: 'daily',
    },
    {
      label: 'Weekdays',
      value: 'weekdays',
    },
    {
      label: 'Weekly',
      value: 'weekly',
    },
    {
      label: 'Monthly',
      value: 'monthly',
    },
    {
      label: 'Annually',
      value: 'annually',
    },
    {
      label: 'Custom interval',
      value: 'custom',
    },
  ];

  return { weekdays, frequency };
};

export const getTourIdByLanguage = (language?: string) => {
  if (language == 'en') {
    return 424143;
  }

  if (language == 'fi') {
    return 428369;
  }

  // if (language == 'ru') {
  //   return 407237;
  // }

  return 407237;
};
