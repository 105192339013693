import { DateSelectArg } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import { createAction } from '@reduxjs/toolkit';

import { pick } from 'lodash';
import moment from 'moment';

export interface Task {
  action: string | null;
  task_name: string;
  initials: string;
  user_ids: string[] | null;
  position_id: string | null;
  position_order: string | null;
  start_date: string;
  end_date: string;
  division_id?: string | null;
  work_type_id?: string | null;
  work_number?: string | null;
  address?: string | null;
  comment: string;
  files?: any;
  is_preset: boolean | null;
}

export interface Shift {
  action: string | null;
  shift_name: string;
  initials: string;
  user_ids: string[] | null;
  position_id: string | null;
  position_order: string | null;
  start_date: string;
  end_date: string;
  additional_break_time: string;
  total_time: string;
  comment: string;
  is_preset: boolean | null;
  is_all_day: boolean | null;
}

export interface Absence {
  action: string | null;
  user_ids: string[] | null;
  position_id: string | null;
  position_order: string | null;
  type_id: string;
  started_at: string;
  ended_at: string;
  comment: string;
}

export interface Position {
  name: string;
  division_id?: string;
  auto_end_shift?: boolean;
  auto_end_shift_time_value?: number;
  order_number?: number;
}

export interface Order {
  orders: any;
  position_id?: any;
}

export interface Assign {
  userId?: number;
  position_id?: any;
  position_ids?: any[];
}

export const addNewEvent = (selectInfo: DateSelectArg, response: any, view: any) => {
  const calendarView = selectInfo?.view || view;

  Object.values(response).forEach((event: any) => {
    let calendarApi = calendarView.calendar;
    let scheduleEvent = calendarApi.addEvent(event);
    scheduleEvent?.setResources(event.resourceId);
  });

  calendarView?.calendar.unselect();
};

export const clearSelected = (events: any) => {
  events.forEach((event: any) => {
    event.setExtendedProp('selected', false);
  });
};

export const updateEvent = (event: EventImpl, response: any) => {
  if (event.title !== response.title) {
    event.setProp('title', response.title);
  }

  if (event.extendedProps.total !== response.duration) {
    event.setProp('duration', response.duration);
  }

  let responseProps = { ...response, ...response.extendedProps };
  let defaultProps = ['is_preset', 'is_repeating'] as any;
  let eventProps = [
    'name',
    'initials',
    'total',
    'started_at',
    'ended_at',
    'comment',
    'customColor',
  ];

  const booleanProps = ['is_preset', 'is_repeating', 'is_night_shift', 'is_all_day', 'is_shift_manager'];

  if (response.type === 'shift') {
    const shiftProps = [
      'total_time',
      'is_night_shift',
      'is_all_day',
      'is_shift_manager',
      'additional_break_time',
      'shift_manager',
    ];

    responseProps = pick(responseProps, [...defaultProps, ...eventProps, ...shiftProps]);
  }

  if (response.type === 'task') {
    responseProps = pick(responseProps, [...defaultProps, ...eventProps]);
  }

  if (response.type === 'absence') {
    const absenceProps = ['type_id', 'work_leave'];
    responseProps = pick(responseProps, [...defaultProps, ...eventProps, ...absenceProps]);
  }

  Object.entries(responseProps).forEach((item: any) => {
    let originalValue = event.extendedProps[item[0]];
    let newValue = item[1];

    if (booleanProps.includes(item[0])) {
      originalValue = Boolean(originalValue);
      newValue = Boolean(newValue);
    }

    if (originalValue !== newValue) {
      event.setExtendedProp(item[0], item[1]);

      if (item[0] == 'started_at') {
        event.setStart(item[1]);
      }

      if (item[0] == 'ended_at') {
        let endStamp = item[1];
        if (response.type === 'absence') {
          endStamp = moment(item[1])
            .add(1, 'day')
            .format('Y-m-d');
        }

        event.setEnd(endStamp);
      }
    }
  });

  if (Boolean(response.allDay)) {
    event.setAllDay(true);
  }

  event.setResources(response.resourceId);
};

export const onHandleUpdateEvent = (
  action: any,
  event: any,
  selectInfo: any,
  calendar: any,
  status: any,
  data: any,
) => {
  if (action === 'resizing' || action === 'dropping') {
    event.remove();
    addNewEvent(selectInfo, data, calendar.current.getApi().view);
    return;
  }

  if (status && data.length > 1) {
    event.remove();
    addNewEvent(selectInfo, data, calendar.calendar.view);
    return;
  }

  if (status && data.length === 1) {
    updateEvent(event, data[0]);
  }
};

export const shiftManagerEvent = (event: EventImpl, shiftManager: any) => {
  event.setExtendedProp('shift_manager', shiftManager);
};

export const confirmEvent = (event: EventImpl, confirmed: any) => {
  event.setProp('editable', confirmed);
  event.setExtendedProp('confirmed', !confirmed);
};

export const fetchWorkerSchedule = createAction<{ id?: number; userId?: number; query: string }>(
  'schedule/fetchWorkerSchedule/fetching',
);

export const fetchManagerSchedule = createAction<{ id?: number; query: string }>(
  'schedule/fetchManagerSchedule/fetching',
);

export const createMultiSchedules = createAction<{ request: any; companyId: any; info: any; view: any }>(
  'schedule/createMultiSchedules/updating',
);

export const confirmScheduledEvent = createAction<{ event?: EventImpl; confirmed?: boolean }>(
  'schedule/confirmSchedule/updating',
);
export const confirmAllScheduledEvents = createAction<{ ids?: number[]; events?: any[]; confirm?: boolean }>(
  'schedule/confirmAllSchedule/updating',
);

export const deleteSchedule = createAction<{ id?: number }>('schedule/deleteSchedule/updating');
export const deleteBulkSchedule = createAction<{ ids?: number[] }>('schedule/deleteBulkSchedule/updating');

export const fetchPresets = createAction<{ id?: number; query: string }>('schedule/fetchPresets/fetching');
export const updatePreset = createAction<{ id?: number; request: Task }>('schedule/updatePreset/updating');
export const updateSavedShift = createAction<{ id?: number; request: Shift }>('schedule/updateSavedShift/updating');
export const deletePreset = createAction<{ id?: number }>('schedule/deletePreset/updating');

export const createShift = createAction<{ request: Shift; info: any; view: any; event?: any }>(
  'schedule/createShift/updating',
);
export const updateShift = createAction<{ id?: number; request: Shift; info: any; calendar: any; event: any }>(
  'schedule/updateShift/updating',
);

export const updateShiftManager = createAction<{ request?: any; events: any[] }>(
  'schedule/updateShiftManager/updating',
);

export const createTask = createAction<{ request: Task; info: any; view: any; event?: any }>(
  'schedule/createTask/updating',
);
export const updateTask = createAction<{ id?: number; request: Task; info: any; calendar: any; event: any }>(
  'schedule/updateTask/updating',
);

export const createAbsence = createAction<{ request: Absence; info: any; view: any }>(
  'schedule/createAbsence/updating',
);
export const updateAbsence = createAction<{ id?: number; request: Absence; info: any; calendar: any; event: any }>(
  'schedule/updateAbsence/updating',
);

export const fetchPositions = createAction<{ id?: number }>('schedule/fetchPositions/fetching');
export const createPosition = createAction<{ id?: number; request: Position }>('schedule/createPosition/updating');
export const updatePosition = createAction<{ id?: number; request: Position }>('schedule/updatePosition/updating');
export const arrangePosition = createAction<{ id?: number; request: Order }>('schedule/arrangePosition/updating');
export const deletePosition = createAction<{ id?: number }>('schedule/deletePosition/updating');

export const assignPosition = createAction<{ id?: number; request: Assign }>('schedule/addToPosition/updating');
export const arrangePeople = createAction<{ id?: number; request: Order }>('schedule/arrangePeople/updating');
export const detachPosition = createAction<{ id?: number; request?: Assign }>('schedule/detachPosition/updating');
