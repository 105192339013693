const refreshed = 'force-refreshed';

export const retryPageLoading = () => {
  const pageRefreshed = JSON.parse(window.localStorage.getItem(refreshed) || 'false') as boolean;

  if (!pageRefreshed) {
    window.localStorage.setItem(refreshed, 'true');
    return window.location.reload();
  }

  window.localStorage.setItem(refreshed, 'false');
};
